import React, { ReactElement } from 'react';
import { navigate } from 'gatsby';

import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

const ConceptContainer = styled.main`
  transition-delay: 100ms;
  transition-duration: 1000ms;
  transition-timing-function: ease-in-out;
  transition-property: all;
  transform: translateY(1rem);
  opacity: ${({ show = false }: { show?: boolean }): string => (show ? `1` : `0`)};
`;

const ImageWithTextModuleContainer = styled.div`
  display: flex;
  margin: 4rem 24rem !important;

  @media only screen and (max-width: 991px) {
    margin: 1rem !important;
  }
`;

const ImageContainer = styled.div`
  @media only screen and (max-width: 991px) {
  }
`;

const ConceptSection = styled.div``;

const content = {
  imageWithTextModule: {
    image: 'https://cdn.shopify.com/s/files/1/0012/4079/3193/files/PS22_Concept.jpg?v=1624124282',
    imageAlt: 'Pre Spring 22',
    header: '',
    body: ''
  }
};

const IndexPage = ({ isVip = false }: { isVip?: boolean }): ReactElement => {
  const [ref, inView] = useInView({ triggerOnce: true });
  if (isVip) {
    navigate('/');
  }

  return (
    <ConceptContainer
      ref={ref}
      show={inView}
      className="main-content1 js-focus-hidden"
      id="MainContent"
      role="main"
      tabIndex={-1}>
      <div className="__pf" id="__pf">
        <div data-pf-type="Body" className="sc-pRgDJ fzXWJx pf-68d7de2a">
          <div data-pf-type="Layout" className="pf-1b68d7de">
            <ConceptSection
              data-container="false"
              className="sc-pZopv bjKdkd"
              data-pf-type="Section">
              <div className="sc-pQQXS iWCqut">
                <ImageWithTextModuleContainer className="sc-qPjXN eYzxUD pf-r" data-pf-type="Row">
                  <ImageContainer>
                    <img
                      src={content.imageWithTextModule.image}
                      alt={content.imageWithTextModule.imageAlt}
                    />
                  </ImageContainer>
                </ImageWithTextModuleContainer>
              </div>
            </ConceptSection>
          </div>
        </div>
      </div>
    </ConceptContainer>
  );
};

export default IndexPage;
